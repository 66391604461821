<app-dashboard-layout>
    <div class="w-full" *ngIf="channel">
        <div class="h-[49px] w-full pr-4 pl-5 flex items-center shadow-content-header bg-blue-400">
            <div class="flex items-center gap-1 cursor-pointer" (click)="openDialog()">
                <svg data-2ui="true" *ngIf="!channel.private" aria-hidden="true" data-qa="sidebar-channel-icon-prefix"
                    data-sidebar-channel-icon="channel" viewBox="0 0 20 20"
                    class="w-[18px] h-[18px] ml-10 2md:ml-0 text-lg text-white flex-none" style="--s:16px;">
                    <path fill="currentColor" fill-rule="evenodd"
                        d="M9.74 3.878a.75.75 0 1 0-1.48-.255L7.68 7H3.75a.75.75 0 0 0 0 1.5h3.67L6.472 14H2.75a.75.75 0 0 0 0 1.5h3.463l-.452 2.623a.75.75 0 0 0 1.478.255l.496-2.878h3.228l-.452 2.623a.75.75 0 0 0 1.478.255l.496-2.878h3.765a.75.75 0 0 0 0-1.5h-3.506l.948-5.5h3.558a.75.75 0 0 0 0-1.5h-3.3l.54-3.122a.75.75 0 0 0-1.48-.255L12.43 7H9.2l.538-3.122ZM11.221 14l.948-5.5H8.942L7.994 14h3.228Z"
                        clip-rule="evenodd"></path>
                </svg>
                <svg data-2ui="true" *ngIf="channel.private" aria-hidden="true" data-qa="sidebar-channel-icon-prefix"
                    data-sidebar-channel-icon="lock" viewBox="0 0 20 20"
                    class="w-[18px] h-[18px] ml-10 2md:ml-0 text-lg text-white flex-none" style="--s:16px;">
                    <path fill="currentColor" fill-rule="evenodd"
                        d="M10 1.5A4.5 4.5 0 0 0 5.5 6v1.5h-.25A2.25 2.25 0 0 0 3 9.75v6.5c0 .966.784 1.75 1.75 1.75h10.5A1.75 1.75 0 0 0 17 16.25v-6.5a2.25 2.25 0 0 0-2.25-2.25h-.25V6A4.5 4.5 0 0 0 10 1.5Zm3 6V6a3 3 0 1 0-6 0v1.5h6ZM4.5 9.75A.75.75 0 0 1 5.25 9h9.5a.75.75 0 0 1 .75.75v6.5a.25.25 0 0 1-.25.25H4.75a.25.25 0 0 1-.25-.25v-6.5Z"
                        clip-rule="evenodd"></path>
                </svg>
                <div class="text-lg font-black text-white">{{channel.name}}</div>
                <svg data-2ui="true" aria-hidden="true" viewBox="0 0 20 20"
                    class="w-[18px] h-[18px] flex-none text-white">
                    <path fill="currentColor" fill-rule="evenodd"
                        d="M5.72 7.47a.75.75 0 0 1 1.06 0L10 10.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-3.75 3.75a.75.75 0 0 1-1.06 0L5.72 8.53a.75.75 0 0 1 0-1.06Z"
                        clip-rule="evenodd"></path>
                </svg>
            </div>
        </div>
        <div class="w-full h-[calc(100vh-93px)]">
            <iframe [src]="channel.url | safe" frameborder="0" class="w-full h-full"></iframe>
        </div>
    </div>
</app-dashboard-layout>