<div class="bg-[#072336]">
    <app-header class="flex-none"></app-header>

    <mat-sidenav-container class="h-[calc(100vh-44px)]">
        <mat-sidenav #sidenav="matSidenav">
            <app-sidebar></app-sidebar>
        </mat-sidenav>
        <mat-sidenav-content class="relative">
            <div class="absolute top-[9px] left-5" *ngIf="sidenav.mode === 'over'">
                <app-tooltip position="bottom-left" tooltip="Open workspace navigation"
                    [controls]="['Ctrl', 'Shift', 'D']">
                    <button (click)="sidenav.toggle()"
                        class="w-8 h-8 rounded-primary bg-transparent text-white hover:bg-primary-04">
                        <span class="c-icon c-icon-sidepane text-xl w-5 h-5"></span>
                    </button>
                </app-tooltip>
            </div>
            <ng-content></ng-content>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>