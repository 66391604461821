import { Injectable } from '@angular/core'
import { Channel, Folder } from './channel.type'

const initialData: Folder[] = [
  {
    id: '1',
    name: 'My Boards',
    channels: [
      {
        id: '1',
        name: 'General',
        folder: '1',
        url: 'https://meet.withAlleo.com/board/shbdyvgex6dose81diti8xcj',
        private: false,
        users: [],
      },
    ],
    open: true,
  },
  {
    id: '2',
    name: 'My Websites',
    channels: [
      {
        id: '2',
        name: 'Bing Search',
        folder: '2',
        url: 'https://bing.com/',
        private: false,
        users: [],
      },
      {
        id: '3',
        name: 'News',
        folder: '2',
        url: 'https://bing.com/news',
        private: false,
        users: [],
      },
    ],
    open: true,
  },
]

@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  folders: Folder[] = []
  constructor() {
    let json = localStorage.getItem('channels')
    if (json) {
      if (this.isJsonString(json)) {
        this.folders = JSON.parse(json)
      } else {
        this.folders = initialData
      }
    } else {
      this.folders = initialData
    }
  }

  addChannel(newChannel: Channel) {
    let index = this.folders.findIndex((e) => e.id == newChannel.folder)
    if (index != -1) {
      this.folders[index].channels = [
        ...this.folders[index].channels,
        newChannel,
      ]
    }
    this.handleCache()
  }

  deleteChannel(id: string) {
    for (let index = 0; index < this.folders.length; index++) {
      let folder = this.folders[index]
      let indexChannel = folder.channels.findIndex((e) => e.id == id)
      if (indexChannel !== -1) {
        this.folders[index].channels = this.folders[index].channels.filter(
          (e) => e.id !== id,
        )
      }
    }
    window.postMessage({ type: 'update_channel' })
    this.handleCache()
  }

  deleteFolder(id: string) {
    let indexFolder = this.folders.findIndex((e) => e.id == id)
    if (indexFolder !== -1) {
      this.folders = this.folders.filter((e) => e.id !== id)
    }
    window.postMessage({ type: 'update_channel' })
    this.handleCache()
  }

  updateChannel(channel: Channel) {
    for (let index = 0; index < this.folders.length; index++) {
      let folder = this.folders[index]
      if (folder.id == channel.folder) {
        let indexChannel = folder.channels.findIndex((e) => e.id == channel.id)
        if (indexChannel === -1) {
          this.folders[index].channels.push(channel)
        } else {
          this.folders[index].channels[indexChannel] = channel
        }
      } else {
        let indexChannel = folder.channels.findIndex((e) => e.id == channel.id)
        if (indexChannel !== -1) {
          this.folders[index].channels = this.folders[index].channels.filter(
            (e) => e.id !== channel.id,
          )
        }
      }
    }
    window.postMessage({ type: 'update_channel' })
    this.handleCache()
  }

  addFolder(newFolder: Folder) {
    this.folders.push(newFolder)
  }

  handleCache() {
    localStorage.setItem('channels', JSON.stringify(this.folders))
  }

  isJsonString(str: string) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }
}
