<div class="flex flex-col min-h-screen">
    <div class="grid grid-cols-3 items-center py-12">
        <div></div>
        <div class="flex items-center justify-center">
            <img src="../../assets/alleo-logo.png" alt="Logo" class="h-[26px]">
        </div>
        <div></div>
    </div>
    <div class="max-w-[800px] mx-auto flex flex-col items-center justify-center mb-auto">
        <h1 class="text-5xl font-bold mb-[10px] text-primary text-center leading-[46px] font-alleo-larsseit">First,
            enter your email </h1>
        <div class="text-lg mb-8 text-[#454245] text-center">We suggest using the <strong>email address you use at
                work.</strong></div>
        <div class="w-full xs:w-[400px] px-3 xs:px-0">
            <input type="text"
                class="w-full h-11 rounded-primary mb-5 flex items-center px-4 border border-primary-30 text-primary-30 text-lg outline-none"
                placeholder="name@work-email.com">
            <button (click)="handleSignUp()"
                class="w-full h-11 flex justify-center items-center bg-[#072336] hover:bg-[#113c58] text-white text-lg font-bold rounded-primary border-none">Continue
            </button>
            <div class="flex items-center py-6">
                <hr class="flex-1 border border-solid border-[#ddd]">
                <div class="px-5 uppercase text-[15px] text-primary">or</div>
                <hr class="flex-1 border border-solid border-[#ddd]">
            </div>
            <button (click)="handleSignUp()"
                class="w-full h-11 flex justify-center items-center gap-3 border-2 border-solid border-[#4285f4] rounded-primary mb-4">
                <img src="../../assets/google_icon.svg" alt="Google Logo" class="w-[18px] h-[18px] flex-none">
                <div class="text-[#4285f4] text-lg font-bold">Continue With Google</div>
            </button>
            <button (click)="handleSignUp()"
                class="w-full h-11 flex justify-center items-center gap-3 border-2 border-solid border-primary rounded-primary">
                <img src="../../assets/microsoft_icon.svg" alt="Google Logo" class="w-[18px] h-[18px] flex-none">
                <div class="text-primary text-lg font-bold">Continue With Microsoft</div>
            </button>
            <div class="mt-6 text-center">
                <div class="text-[#616061] text-[15px] font-normal mb-[1px]">Already using Alleo?</div>
                <a href="#" class="text-center text-[#1264a3] no-underline hover:underline text-[15px] font-normal">Sign
                    in to an existing workspace</a>
            </div>
        </div>
    </div>
    <div class="flex justify-center items-center py-8 gap-4">
        <a href="#"
            class="mb-1 text-[#696969] hover:text-[#0b4c8c] no-underline hover:underline text-[15px] font-medium">
            Privacy & Terms
        </a>
        <a href="#"
            class="mb-1 text-[#696969] hover:text-[#0b4c8c] no-underline hover:underline text-[15px] font-medium">
            Contact Us
        </a>
        <a href="#"
            class="mb-1 text-[#696969] hover:text-[#0b4c8c] no-underline hover:underline text-[15px] font-medium flex items-center">
            <div class="c-icon c-icon-globe mr-1 "></div>
            Change region
            <div class="c-icon c-icon-down"></div>
        </a>
    </div>
</div>