<div class="flex flex-col min-h-screen">
    <div class="grid grid-cols-3 items-center py-12">
        <div></div>
        <div class="flex items-center justify-center">
            <img src="../../assets/alleo-logo.png" alt="Logo" class="h-[26px]">
        </div>
        <div class="hidden xs:flex justify-end items-end flex-col pr-10 text-[13px] text-[#616061]">
            New to Alleo?<br />
            <a href="/signup" class="text-[#1264a3] no-underline hover:underline font-bold">
                Create an account
            </a>
        </div>
    </div>
    <div class="max-w-[800px] mx-auto flex flex-col items-center justify-center mb-auto">
        <h1 class="text-5xl font-bold mb-[10px] text-primary text-center font-alleo-larsseit">Sign in to Alleo</h1>
        <div class="text-lg mb-8 text-[#454245] text-center">We suggest using the <strong>email address you use at
                work.</strong></div>
        <div class="w-full xs:w-[400px] px-3 xs:px-0">
            <button (click)="handleSignIn()"
                class="w-full h-11 flex justify-center items-center gap-3 border-2 border-solid border-[#4285f4] rounded-primary mb-4">
                <img src="../../assets/google_icon.svg" alt="Google Logo" class="w-[18px] h-[18px] flex-none">
                <div class="text-[#4285f4] text-lg font-bold">Sign In With Google</div>
            </button>
            <button (click)="handleSignIn()"
                class="w-full h-11 flex justify-center items-center gap-3 border-2 border-solid border-primary rounded-primary">
                <img src="../../assets/microsoft_icon.svg" alt="Google Logo" class="w-[18px] h-[18px] flex-none">
                <div class="text-primary text-lg font-bold">Sign In With Microsoft</div>
            </button>
            <div class="flex items-center py-6">
                <hr class="flex-1 border border-solid border-[#ddd]">
                <div class="px-5 uppercase text-[15px] text-primary">or</div>
                <hr class="flex-1 border border-solid border-[#ddd]">
            </div>
            <input type="text"
                class="w-full h-11 rounded-primary mb-5 flex items-center px-4 border border-primary-30 text-primary-30 text-lg outline-none"
                placeholder="name@work-email.com">
            <button (click)="handleSignIn()"
                class="w-full h-11 flex justify-center items-center bg-[#072336] text-white mb-5 text-lg font-bold rounded-primary border-none">Sign
                In With Email</button>
            <div class="w-full py-3 px-6 rounded-[8px] bg-[#1d1c1d0d]  text-[#616061] flex gap-3">
                <div class="c-icon c-icon-sparkle"></div>
                <div class="flex-1 text-[#1d1c1d80] text-[15px]">We’ll email you a magic code for a password-free sign
                    in.
                    Or you can
                    <a href="/login" class="text-[#1264a3] no-underline hover:underline">sign in manually instead</a>.
                </div>
            </div>
        </div>
    </div>
    <div class="flex justify-center items-center py-8 gap-4">
        <a href="#"
            class="mb-1 text-[#696969] hover:text-[#0b4c8c] no-underline hover:underline text-[15px] font-medium">
            Privacy & Terms
        </a>
        <a href="#"
            class="mb-1 text-[#696969] hover:text-[#0b4c8c] no-underline hover:underline text-[15px] font-medium">
            Contact Us
        </a>
        <a href="#"
            class="mb-1 text-[#696969] hover:text-[#0b4c8c] no-underline hover:underline text-[15px] font-medium flex items-center">
            <div class="c-icon c-icon-globe mr-1 "></div>
            Change region
            <div class="c-icon c-icon-down"></div>
        </a>
    </div>
</div>