<div mat-dialog-content>
    <div class="w-[464px] tracking-normal max-w-full">
        <div class="flex justify-between items-center mb-5">
            <div class="text-xl text-primary font-black">Delete this folder?</div>
            <button [matDialogClose]="true"
                class="w-9 h-9 flex items-center justify-center rounded-primary text-primary-70 hover:text-primary bg-transparent hover:bg-primary-04">
                <svg data-2ui="true" aria-hidden="true" viewBox="0 0 20 20" class="w-5 h-5" style="--s:20px;">
                    <path fill="currentColor" fill-rule="evenodd"
                        d="M15.303 4.697a.75.75 0 0 1 0 1.06L11.061 10l4.242 4.243a.75.75 0 0 1-1.06 1.06L10 11.061l-4.243 4.242a.75.75 0 0 1-1.06-1.06L8.939 10 4.697 5.757a.75.75 0 0 1 1.06-1.06L10 8.939l4.243-4.242a.75.75 0 0 1 1.06 0Z"
                        clip-rule="evenodd"></path>
                </svg>
            </button>
        </div>
        <div class="text-primary-70 text-primary-size mb-2">
            When you delete a folder, all messages from this folder will be removed from Slack immediately.
            <strong>This can’t be undone.</strong>
        </div>
        <div class="text-primary-70 text-primary-size">Keep in mind that:</div>
        <ul class="text-primary-70 text-primary-size mb-5 list-disc pl-5">
            <li class="leading-5">Any files uploaded to this folder won’t be removed</li>
            <li class="leading-5">You can <a href="#" class="text-[#0b4c8c]">archive a folder instead</a> without
                removing its messages
            </li>
        </ul>
        <div class="flex items-center justify-between">
            <div class="flex items-center mr-2">
                <input type="checkbox" id="permant_delete" name="permant_delete" [(ngModel)]="permanentDelete">
                <label for="permant_delete" class="ml-2 text-primary-size text-primary-70">
                    Yes, permanently delete the folder
                </label>
            </div>
            <div class="flex gap-2">
                <button class="text-primary-70 font-bold h-9 px-3 border-primary-30 border rounded-primary"
                    [matDialogClose]="true">Cancel</button>
                <button
                    class="text-white font-bold h-9 px-3 bg-[#e01e5a] border-0 rounded-primary disabled:bg-[#dddddd] disabled:text-primary-70"
                    [disabled]="!permanentDelete" (click)="handleDelete()">Delete
                    folder</button>
            </div>
        </div>
    </div>
</div>