import { Component } from '@angular/core'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop'
import { Channel, Folder } from 'src/app/services/channel/channel.type'
import { ChannelService } from 'src/app/services/channel/channel.service'
import { MatDialog } from '@angular/material/dialog'
import { CreateChannelDialogComponent } from 'src/app/component/dialog/create-channel-dialog/create-channel-dialog.component'
import { DeleteFolderDialogComponent } from 'src/app/component/dialog/delete-folder-dialog/delete-folder-dialog.component'

@Component({
  selector: 'sidebar-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
})
export class TreeComponent {
  folders: Folder[] = []

  constructor(
    private channelService: ChannelService,
    public dialog: MatDialog,
  ) {
    this.folders = channelService.folders
  }

  toggleFolder(folder: Folder) {
    this.folders.forEach((e, index) => {
      if (e.id == folder.id) {
        e.open = !e.open
      }
    })
  }

  onDrop(event: CdkDragDrop<Channel[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      )
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      )
    }
  }

  openDialog(folder: string) {
    this.dialog.open(CreateChannelDialogComponent, {
      data: { folder: folder },
    })
  }

  deleteFolder(folder: string) {
    this.dialog.open(DeleteFolderDialogComponent, {
      data: { id: folder },
    })
  }
}
