<div mat-dialog-content>
    <div class="w-[464px] tracking-normal max-w-full">
        <form [formGroup]="channelForm" (ngSubmit)="onSubmit()">
            <div class="flex justify-between items-center mb-5">
                <ng-container *ngIf="!editMode">
                    <div class="text-primary text-[28px] font-black" *ngIf="channelForm.value.private">Create a
                        private board</div>
                    <div class="text-primary text-[28px] font-black" *ngIf="!channelForm.value.private">Create a board
                    </div>
                </ng-container>
                <div class="text-primary text-[28px] font-black" *ngIf="editMode">Edit board</div>
                <button [matDialogClose]="true"
                    class="w-9 h-9 flex items-center justify-center rounded-primary text-primary-70 hover:text-primary bg-transparent hover:bg-primary-04">
                    <svg data-2ui="true" aria-hidden="true" viewBox="0 0 20 20" class="w-5 h-5" style="--s:20px;">
                        <path fill="currentColor" fill-rule="evenodd"
                            d="M15.303 4.697a.75.75 0 0 1 0 1.06L11.061 10l4.242 4.243a.75.75 0 0 1-1.06 1.06L10 11.061l-4.243 4.242a.75.75 0 0 1-1.06-1.06L8.939 10 4.697 5.757a.75.75 0 0 1 1.06-1.06L10 8.939l4.243-4.242a.75.75 0 0 1 1.06 0Z"
                            clip-rule="evenodd"></path>
                    </svg>
                </button>
            </div>
            <div class="text-[#616061] text-[15px] leading-[22px] mb-6">Boards are where your team communicates.
                They’re
                best when
                organized around a topic — #marketing, for example.</div>
            <div class="w-full mb-5">
                <label for="name" class="text-primary text-[15px]">Name</label>
                <div class="w-full h-11 rounded-primary flex items-center mt-2 border border-primary-30 px-3">
                    <svg data-2ui="true" *ngIf="!channelForm.value.private" aria-hidden="true"
                        data-qa="sidebar-channel-icon-prefix" data-sidebar-channel-icon="channel" viewBox="0 0 20 20"
                        class="w-[18px] h-[18px] text-lg text-[#616061] flex-none" style="--s:16px;">
                        <path fill="currentColor" fill-rule="evenodd"
                            d="M9.74 3.878a.75.75 0 1 0-1.48-.255L7.68 7H3.75a.75.75 0 0 0 0 1.5h3.67L6.472 14H2.75a.75.75 0 0 0 0 1.5h3.463l-.452 2.623a.75.75 0 0 0 1.478.255l.496-2.878h3.228l-.452 2.623a.75.75 0 0 0 1.478.255l.496-2.878h3.765a.75.75 0 0 0 0-1.5h-3.506l.948-5.5h3.558a.75.75 0 0 0 0-1.5h-3.3l.54-3.122a.75.75 0 0 0-1.48-.255L12.43 7H9.2l.538-3.122ZM11.221 14l.948-5.5H8.942L7.994 14h3.228Z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <svg data-2ui="true" *ngIf="channelForm.value.private" aria-hidden="true"
                        data-qa="sidebar-channel-icon-prefix" data-sidebar-channel-icon="lock" viewBox="0 0 20 20"
                        class="w-[18px] h-[18px] text-lg text-[#616061] flex-none" style="--s:16px;">
                        <path fill="currentColor" fill-rule="evenodd"
                            d="M10 1.5A4.5 4.5 0 0 0 5.5 6v1.5h-.25A2.25 2.25 0 0 0 3 9.75v6.5c0 .966.784 1.75 1.75 1.75h10.5A1.75 1.75 0 0 0 17 16.25v-6.5a2.25 2.25 0 0 0-2.25-2.25h-.25V6A4.5 4.5 0 0 0 10 1.5Zm3 6V6a3 3 0 1 0-6 0v1.5h6ZM4.5 9.75A.75.75 0 0 1 5.25 9h9.5a.75.75 0 0 1 .75.75v6.5a.25.25 0 0 1-.25.25H4.75a.25.25 0 0 1-.25-.25v-6.5Z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <input type="text"
                        class="h-full flex-1 text-primary-70 border-none outline-none bg-transparent text-lg name-input"
                        formControlName="name">
                    <span class="text-primary flex-none text-lg name-left">{{80 -
                        (channelForm.value.name ? channelForm.value.name.length : 0)}}</span>
                </div>
            </div>
            <div class="w-full mb-5">
                <label for="folder" class="text-primary text-[15px]">Folder</label>
                <div class="w-full h-11 rounded-primary flex items-center mt-2 border border-primary-30">
                    <select id="folder"
                        class="h-full flex-1 text-primary-70 border-none outline-none bg-transparent text-lg  px-3"
                        formControlName="folder">
                        <option *ngFor="let folder of folders" [value]="folder.id">{{folder.name}}</option>
                    </select>
                </div>
            </div>
            <div class="w-full mb-5">
                <label for="url" class="text-primary text-[15px]">URL</label>
                <div class="w-full h-11 rounded-primary flex items-center mt-2 border border-primary-30 px-3 mb-1">
                    <input type="text"
                        class="h-full flex-1 text-primary-70 border-none outline-none bg-transparent text-lg"
                        formControlName="url" placeholder="https://example.com">
                </div>
                <div class="text-primary-70 text-   primary-size">What is the link for iframe?</div>
            </div>
            <div class="w-full flex items-center justify-between mb-4 gap-2 md:gap-[104px]">
                <label class="flex-1 cursor-pointer" for="private_ch    annel">
                    <div class="text-primary text-lg font-black leading-[22px]">Make private
                    </div>
                    <div class="text-primary-70 text-primary-size leading-[22px]" *ngIf="channelForm.value.private">
                        <strong>This can’t be undone.</strong>
                        A
                        private board
                        cannot
                        be made public
                        later on.
                    </div>
                    <div class="text-primary-70 text-primary-size leading-[22px]" *ngIf="!channelForm.value.private">
                        When a board is set to private, it can only be viewed or joined by invitation.</div>
                </label>
                <div class="c-switch">
                    <input id="channel_create_modal_toggle" type="checkbox" class="c-switch__input" role="switch"
                        id="private_channel" formControlName="private">
                    <div class="c-switch__visual" tabindex="-1"></div>
                </div>
            </div>
            <div class="flex items-center">
                <div class="flex items-center mr-2">
                    <input type="checkbox" id="share_outside" name="share_outside">
                    <label for="share_outside" class="ml-2 text-primary-size text-primary-70">Share outside
                        Alleo</label>
                </div>
                <app-tooltip position="top"
                    tooltip="Sharing a board lets you work with other companies, right in Slack.">
                    <span class="c-icon c-icon-info text-primary-size w-[15px] h-[15px]"></span>
                </app-tooltip>
                <div
                    class="py-1 px-3 h-7 ml-2 text-primary-size text-[#886607] font-bold rounded-full bg-[#fcf4da] uppercase">
                    Premium</div>
                <button
                    class="ml-auto px-3 h-9 flex items-center justify-center text-white disabled:text-primary bg-[#007a5a] disabled:bg-[#dddddd] rounded-primary"
                    [disabled]="!channelForm.valid">{{ editMode ? 'Update' : 'Create'}}</button>
            </div>
        </form>
    </div>
</div>