import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { Folder } from 'src/app/services/channel/channel.type'
import { ChannelService } from 'src/app/services/channel/channel.service'
import { FormBuilder } from '@angular/forms'
import { Validators } from '@angular/forms'

@Component({
  selector: 'app-create-folder-dialog',
  templateUrl: './create-folder-dialog.component.html',
  styleUrls: ['./create-folder-dialog.component.scss'],
})
export class CreateFolderDialogComponent {
  folderForm = this.fb.group({
    name: ['', Validators.required],
  })
  folders: Folder[] = this.channelService.folders

  constructor(
    public dialogRef: MatDialogRef<CreateFolderDialogComponent>,
    private fb: FormBuilder,
    private channelService: ChannelService,
  ) {}

  onSubmit() {
    let formData: Folder = {
      id: new Date().getMilliseconds().toString(),
      name: '',
      channels: [],
      open: false,
    }

    if (this.folderForm.value) {
      formData.name = this.folderForm.value.name as string
    }

    this.channelService.addFolder(formData)
    this.dialogRef.close()
  }
}
