<div mat-dialog-content>
    <div class="w-[464px] tracking-normal max-w-full">
        <form [formGroup]="folderForm" (ngSubmit)="onSubmit()">
            <div class="flex justify-between items-center mb-5">
                <div class="text-primary text-[28px] font-black">Create a folder</div>
                <button [matDialogClose]="true"
                    class="w-9 h-9 flex items-center justify-center rounded-primary text-primary-70 hover:text-primary bg-transparent hover:bg-primary-04">
                    <svg data-2ui="true" aria-hidden="true" viewBox="0 0 20 20" class="w-5 h-5" style="--s:20px;">
                        <path fill="currentColor" fill-rule="evenodd"
                            d="M15.303 4.697a.75.75 0 0 1 0 1.06L11.061 10l4.242 4.243a.75.75 0 0 1-1.06 1.06L10 11.061l-4.243 4.242a.75.75 0 0 1-1.06-1.06L8.939 10 4.697 5.757a.75.75 0 0 1 1.06-1.06L10 8.939l4.243-4.242a.75.75 0 0 1 1.06 0Z"
                            clip-rule="evenodd"></path>
                    </svg>
                </button>
            </div>
            <div class="text-[#616061] text-[15px] leading-[22px] mb-6">Folder where you can add boards inside.</div>
            <div class="w-full mb-5">
                <label for="name" class="text-primary text-[15px]">Name</label>
                <div class="w-full h-11 rounded-primary flex items-center mt-2 border border-primary-30 px-3">
                    <input type="text"
                        class="h-full flex-1 text-primary-70 border-none outline-none bg-transparent text-lg name-input"
                        formControlName="name">
                    <span class="text-primary flex-none text-lg name-left">{{80 -
                        (folderForm.value.name ? folderForm.value.name.length : 0)}}</span>
                </div>
            </div>
            <div class="flex items-center">
                <div class="flex items-center mr-2">
                    <input type="checkbox" id="share_outside" name="share_outside">
                    <label for="share_outside" class="ml-2 text-primary-size text-primary-70">Share outside Alleo</label>
                </div>
                <app-tooltip position="top"
                    tooltip="Sharing a board lets you work with other companies, right in Slack.">
                    <span class="c-icon c-icon-info text-primary-size w-[15px] h-[15px]"></span>
                </app-tooltip>
                <div
                    class="py-1 px-3 h-7 ml-2 text-primary-size text-[#886607] font-bold rounded-full bg-[#fcf4da] uppercase">
                    Premium</div>
                <button
                    class="ml-auto px-3 h-9 flex items-center justify-center text-white disabled:text-primary bg-[#007a5a] disabled:bg-[#dddddd] rounded-primary"
                    [disabled]="!folderForm.valid">Create</button>
            </div>
        </form>
    </div>
</div>