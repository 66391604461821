import { Component } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { CreateChannelDialogComponent } from 'src/app/component/dialog/create-channel-dialog/create-channel-dialog.component'
import { CreateFolderDialogComponent } from 'src/app/component/dialog/create-folder-dialog/create-folder-dialog.component'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  channelOpen: boolean = true
  constructor(public dialog: MatDialog, private router: Router) {}

  toggleChannelOpen() {
    this.channelOpen = !this.channelOpen
  }

  openDialog() {
    this.dialog.open(CreateFolderDialogComponent)
  }

  openCreateChannelDialog() {
    this.dialog.open(CreateChannelDialogComponent)
  }

  gotoBrowseChannel() {
    this.router.navigate(['/browse-boards'])
  }
}
