<app-dashboard-layout>
    <div class="w-full">
        <div class="h-[49px] w-full pr-4 pl-5 flex justify-between items-center shadow-content-header">
            <div class="text-lg font-black text-primary pl-10 2md:pl-0">People</div>
        </div>
        <div class="h-9 w-full pl-3 pr-4 shadow-content-header flex items-center">
            <div class="h-full mx-2 flex items-center w-fit text-primary-70 text-primary-size font-bold c-tab c-tab-active">
                Members</div>
        </div>
        <div class="pt-5 px-5">
            <div
                class="px-2 h-9 rounded-primary border flex items-center text-[#616061] border-primary-30 hover:border-primary mb-3">
                <div class="c-icon c-icon-search w-5 h-5 flex items-center justify-center"></div>
                <input type="text" class="flex-1 outline-none border-none bg-transparent pl-2"
                    placeholder="Search by name, role or team">
            </div>
            <div class="flex items-center justify-between">
                <div class="text-primary-70 hover:text-primary text-primary-size truncated-text">2 recommended results
                </div>
                <div class="flex items-center">
                    <div class="cursor-pointer px-2 h-7 flex items-center text-primary-70">
                        <span class="c-icon c-icon-sort w-4 mr-1"></span>
                        <span class="whitespace-nowrap">Sort: Most recommended</span>
                    </div>
                    <div class="cursor-pointer px-2 h-7 flex items-center text-primary-70">
                        <svg _ngcontent-fve-c59="" data-2ui="true" aria-hidden="true" viewBox="0 0 20 20"
                            class="w-4 h-4 ml-1" style="--s: 16px;">
                            <path _ngcontent-fve-c59="" fill="currentColor" fill-rule="evenodd"
                                d="M13.5 3.25a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2.386.25a2.501 2.501 0 0 1 4.772 0h1.864a.75.75 0 0 1 0 1.5h-1.864a2.501 2.501 0 0 1-4.771 0H2.25a.75.75 0 0 1 0-1.5h8.864Zm-7 5.75a2.501 2.501 0 0 1 4.772 0h8.864a.75.75 0 0 1 0 1.5H8.886a2.501 2.501 0 0 1-4.772 0H2.25a.75.75 0 0 1 0-1.5h1.864ZM6.5 9a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm6 5.75a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2.386.25a2.501 2.501 0 0 1 4.772 0h2.864a.75.75 0 0 1 0 1.5h-2.864a2.501 2.501 0 0 1-4.771 0H2.25a.75.75 0 0 1 0-1.5h7.864Z"
                                clip-rule="evenodd"></path>
                        </svg>
                        <span>Filter</span>
                    </div>
                </div>
            </div>
            <div class="w-full h-[calc(100vh-225px)] overflow-auto">
                <div
                    class="grid gap-1 grid-cols-1 grid-2:grid-cols-2 grid-3:grid-cols-3 grid-4:grid-cols-4 grid-5:grid-cols-5 grid-6:grid-cols-6 grid-7:grid-cols-7 grid-8:grid-cols-8 grid-9:grid-cols-9 grid-10:grid-cols-10">
                    <div class="" *ngFor="let user of users">
                        <div class="border border-[#1d1c1d21] rounded-lg cursor-pointer">
                            <div class="w-full h-full relative pb-[100%]">
                                <img src="../../../assets/avatar-big.png" alt="Avatar"
                                    class="rounded-lg w-full h-full absolute left-0">
                            </div>
                            <div class="w-full h-[84px] px-4 py-[10px]">
                                <div class="text-primary text-primary-size font-bold">
                                    {{user.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-dashboard-layout>