<app-dashboard-layout>
    <div class="w-full">
        <div class="h-[49px] w-full pr-4 pl-5 flex justify-between items-center shadow-content-header">
            <div class="text-lg font-black text-primary pl-10 2md:pl-0">All Boards</div>
            <button class="text-primary-70 font-bold h-7 px-3 border-primary-30 border rounded-primary"
                (click)="openDialog()">Create
                Board</button>
        </div>
        <div class="pt-5 px-5">
            <div
                class="px-2 h-9 rounded-primary border flex items-center text-[#616061] border-primary-30 hover:border-primary mb-3">
                <div class="c-icon c-icon-search w-5 h-5 flex items-center justify-center"></div>
                <input type="text" class="flex-1 outline-none border-none bg-transparent pl-2"
                    placeholder="Search by board name or description" [(ngModel)]="searchText">
            </div>
            <div class="flex items-center justify-between">
                <div class="text-primary-70 hover:text-primary text-primary-size truncated-text">4 recommended results
                </div>
                <div class="flex items-center">
                    <div class="cursor-pointer px-2 h-7 flex items-center text-primary-70">
                        <span class="c-icon c-icon-sort w-4 mr-1"></span>
                        <span class="whitespace-nowrap">Sort: Most recommended</span>
                    </div>
                    <div class="cursor-pointer px-2 h-7 flex items-center text-primary-70">
                        <svg _ngcontent-fve-c59="" data-2ui="true" aria-hidden="true" viewBox="0 0 20 20"
                            class="w-4 h-4 ml-1" style="--s: 16px;">
                            <path _ngcontent-fve-c59="" fill="currentColor" fill-rule="evenodd"
                                d="M13.5 3.25a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2.386.25a2.501 2.501 0 0 1 4.772 0h1.864a.75.75 0 0 1 0 1.5h-1.864a2.501 2.501 0 0 1-4.771 0H2.25a.75.75 0 0 1 0-1.5h8.864Zm-7 5.75a2.501 2.501 0 0 1 4.772 0h8.864a.75.75 0 0 1 0 1.5H8.886a2.501 2.501 0 0 1-4.772 0H2.25a.75.75 0 0 1 0-1.5h1.864ZM6.5 9a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm6 5.75a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2.386.25a2.501 2.501 0 0 1 4.772 0h2.864a.75.75 0 0 1 0 1.5h-2.864a2.501 2.501 0 0 1-4.771 0H2.25a.75.75 0 0 1 0-1.5h7.864Z"
                                clip-rule="evenodd"></path>
                        </svg>
                        <span>Filter</span>
                    </div>
                </div>
            </div>
            <div class="w-full h-[calc(100vh-189px)] overflow-auto">
                <div class="w-full">
                    <ng-container *ngFor="let folder of folders">
                        <ng-container *ngFor="let channel of folder.channels">
                            <div class="py-4 px-3 border-t border-primary-30 hover:bg-[#f8f8f8] cursor-pointer"
                                *ngIf="channel.name.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) !== -1"
                                [routerLink]="'/board/' + channel.id">
                                <div class="text-primary font-bold text-[15px] flex items-center">
                                    <svg data-2ui="true" *ngIf="!channel.private" aria-hidden="true"
                                        data-qa="sidebar-channel-icon-prefix" data-sidebar-channel-icon="channel"
                                        viewBox="0 0 20 20" class="w-[15px] h-[15px]" style="--s:16px;">
                                        <path fill="currentColor" fill-rule="evenodd"
                                            d="M9.74 3.878a.75.75 0 1 0-1.48-.255L7.68 7H3.75a.75.75 0 0 0 0 1.5h3.67L6.472 14H2.75a.75.75 0 0 0 0 1.5h3.463l-.452 2.623a.75.75 0 0 0 1.478.255l.496-2.878h3.228l-.452 2.623a.75.75 0 0 0 1.478.255l.496-2.878h3.765a.75.75 0 0 0 0-1.5h-3.506l.948-5.5h3.558a.75.75 0 0 0 0-1.5h-3.3l.54-3.122a.75.75 0 0 0-1.48-.255L12.43 7H9.2l.538-3.122ZM11.221 14l.948-5.5H8.942L7.994 14h3.228Z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                    <svg data-2ui="true" *ngIf="channel.private" aria-hidden="true"
                                        data-qa="sidebar-channel-icon-prefix" data-sidebar-channel-icon="lock"
                                        viewBox="0 0 20 20" class="w-[15px] h-[15px]" style="--s:16px;">
                                        <path fill="currentColor" fill-rule="evenodd"
                                            d="M10 1.5A4.5 4.5 0 0 0 5.5 6v1.5h-.25A2.25 2.25 0 0 0 3 9.75v6.5c0 .966.784 1.75 1.75 1.75h10.5A1.75 1.75 0 0 0 17 16.25v-6.5a2.25 2.25 0 0 0-2.25-2.25h-.25V6A4.5 4.5 0 0 0 10 1.5Zm3 6V6a3 3 0 1 0-6 0v1.5h6ZM4.5 9.75A.75.75 0 0 1 5.25 9h9.5a.75.75 0 0 1 .75.75v6.5a.25.25 0 0 1-.25.25H4.75a.25.25 0 0 1-.25-.25v-6.5Z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                    <span class="ml-[2px]">{{channel.name}}</span>
                                </div>
                                <div class="flex items-center justify-between">
                                    <div class="flex items-center">
                                        <div class="flex items-center text-[#007a5a] gap-[6px]">
                                            <span
                                                class="w-[13px] h-[13px] c-icon c-icon-checked flex items-center"></span>
                                            Joined
                                        </div>
                                        <span class="w-4 h-4 flex items-center justify-center text-primary-30">
                                            ·
                                        </span>
                                        <div class="flex items-center text-primary-30">
                                            {{channel.users.length}} member
                                        </div>
                                        <span class="w-4 h-4 flex items-center justify-center text-primary-30">
                                            ·
                                        </span>
                                        <div class="hidden lg:flex items-center text-primary-30">
                                            {{channel.url}}
                                        </div>
                                    </div>
                                    <div class="">
                                        <button class="border-0 outline-none flex items-center justify-center w-5 h-5"
                                            (click)="openDeleteChannelModal(channel);$event.stopPropagation()">
                                            <span class="c-icon c-icon-delete text-red-500 text-lg"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="my-12 flex justify-center">
                    <button
                        class="h-9 px-3 flex justify-center items-center text-white rounded-primary text-[15px] bg-[#007a5a] hover:bg-[#148567]"
                        (click)="openDialog()">Create
                        Board</button>
                </div>
            </div>
        </div>
    </div>
</app-dashboard-layout>