<div class="w-[220px] xl:w-[260px] bg-[#072336] h-full relative">
    <div
        class="w-full h-[50px] px-4 border-b border-t border-solid border-[#113b57] hover:bg-[#113b57] bg-transparent cursor-pointer flex justify-between items-center absolute top-0 left-0">
        <div class="flex items-center">
            <span class="text-lg font-black text-white">Alleo</span>
            <svg data-2ui="true" aria-hidden="true" viewBox="0 0 20 20" class="w-[18px] h-[18px] flex-none text-white">
                <path fill="currentColor" fill-rule="evenodd"
                    d="M5.72 7.47a.75.75 0 0 1 1.06 0L10 10.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-3.75 3.75a.75.75 0 0 1-1.06 0L5.72 8.53a.75.75 0 0 1 0-1.06Z"
                    clip-rule="evenodd"></path>
            </svg>
        </div>
        <app-tooltip tooltip="Create Board" position="bottom-right">
            <button
                class="w-[34px] h-[34px] rounded-full bg-white outline-none border-none flex justify-center items-center"
                (click)="openCreateChannelDialog()">
                <svg data-2ui="true" aria-hidden="true" viewBox="0 0 20 20" class="w-[18px] h-[18px] text-[#072336]">
                    <path fill="currentColor" fill-rule="evenodd"
                        d="M16.707 3.268a1 1 0 0 0-1.414 0l-.482.482 1.439 1.44.482-.483a1 1 0 0 0 0-1.414l-.025-.025ZM15.19 6.25l-1.44-1.44-5.068 5.069-.431 1.871 1.87-.431L15.19 6.25Zm-.957-4.043a2.5 2.5 0 0 1 3.536 0l.025.025a2.5 2.5 0 0 1 0 3.536L11.03 12.53a.75.75 0 0 1-.361.2l-3.25.75a.75.75 0 0 1-.9-.899l.75-3.25a.75.75 0 0 1 .2-.361l6.763-6.763ZM5.25 4A2.25 2.25 0 0 0 3 6.25v8.5A2.25 2.25 0 0 0 5.25 17h8.5A2.25 2.25 0 0 0 16 14.75v-4.5a.75.75 0 1 1 1.5 0v4.5a3.75 3.75 0 0 1-3.75 3.75h-8.5a3.75 3.75 0 0 1-3.75-3.75v-8.5A3.75 3.75 0 0 1 5.25 2.5h4.5a.75.75 0 0 1 0 1.5h-4.5Z"
                        clip-rule="evenodd"></path>
                </svg>
            </button>
        </app-tooltip>
    </div>
    <div class="pt-[62px] pb-[50px] h-full">
        <div class="w-full h-7 flex items-center px-4 gap-2 hover:bg-[#113b57] bg-transparent text-[#ffffffb3] cursor-pointer menu-link"
            routerLink="/browse-users" routerLinkActive="active">
            <svg data-2ui="true" aria-hidden="true" viewBox="0 0 20 20" class="is-inline w-[18px] h-[18px] flex-none">
                <path fill="currentColor" fill-rule="evenodd"
                    d="M4.75 1.5A1.75 1.75 0 0 0 3 3.25v.5a.75.75 0 0 0 1.5 0v-.5A.25.25 0 0 1 4.75 3h10c.69 0 1.25.56 1.25 1.25v11.5c0 .69-.56 1.25-1.25 1.25h-10a.25.25 0 0 1-.25-.25v-.5a.75.75 0 0 0-1.5 0v.5c0 .966.784 1.75 1.75 1.75h10a2.75 2.75 0 0 0 2.75-2.75V4.25a2.75 2.75 0 0 0-2.75-2.75h-10ZM2.25 6a.75.75 0 0 0 0 1.5h2a.75.75 0 0 0 0-1.5h-2Zm-.75 4a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2A.75.75 0 0 1 1.5 10Zm.75 2.5a.75.75 0 0 0 0 1.5h2a.75.75 0 0 0 0-1.5h-2Zm5.79.472a.228.228 0 0 0 .11.028h4.7a.227.227 0 0 0 .11-.028 2.104 2.104 0 0 0-.736-.991c-.372-.271-.92-.481-1.724-.481-.805 0-1.353.21-1.724.481a2.1 2.1 0 0 0-.736.991Zm4.12-2.702c.078-.086.151-.176.218-.268C12.783 9.437 13 8.712 13 8c0-1.624-1.287-2.5-2.5-2.5S8 6.376 8 8c0 .712.217 1.437.622 2.002.067.092.14.182.219.268a3.608 3.608 0 0 0-2.233 2.255 1.422 1.422 0 0 0 .293 1.431 1.68 1.68 0 0 0 1.249.544h4.7a1.68 1.68 0 0 0 1.249-.544 1.42 1.42 0 0 0 .293-1.431 3.608 3.608 0 0 0-2.233-2.255ZM9.5 8c0-.65.463-1 1-1 .537 0 1 .35 1 1 0 .426-.133.838-.34 1.127-.203.282-.434.398-.66.398-.226 0-.457-.116-.66-.398A1.982 1.982 0 0 1 9.5 8Z"
                    clip-rule="evenodd"></path>
            </svg>
            <span class="text-[15px] font-normal">People & user groups</span>
        </div>
        <div class="w-full h-7 flex items-center px-4 gap-2 hover:bg-[#113b57] bg-transparent text-[#ffffffb3] cursor-pointer menu-link"
            routerLink="/browse-boards" routerLinkActive="active">
            <svg data-2ui="true" aria-hidden="true" viewBox="0 0 20 20" class="is-inline w-[18px] h-[18px] flex-none">
                <path fill="currentColor" fill-rule="evenodd"
                    d="M6.985 3.897a.75.75 0 0 0-1.47-.294L4.935 6.5H2.75a.75.75 0 0 0 0 1.5h1.885l-.8 4H1.75a.75.75 0 0 0 0 1.5h1.785l-.52 2.603a.75.75 0 1 0 1.47.294l.58-2.897h2.47l-.52 2.603a.75.75 0 1 0 1.47.294l.58-2.897h2.185a.75.75 0 0 0 0-1.5H9.365l.07-.353a.75.75 0 1 0-1.47-.294l-.13.647h-2.47l.8-4h.585a.75.75 0 0 0 0-1.5h-.285l.52-2.603ZM13 4.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM9 7a4 4 0 1 1 7.309 2.248l1.471 1.472a.75.75 0 1 1-1.06 1.06l-1.472-1.471A4 4 0 0 1 9 7Z"
                    clip-rule="evenodd"></path>
            </svg>
            <span class="text-[15px] font-normal">All Boards</span>
            <mat-icon></mat-icon>
        </div>
        <div class="w-full border-b border-[#072336] my-3"></div>
        <div class="w-full h-7 flex items-center px-4 gap-2 bg-transparent text-[#ffffffb3] cursor-pointer">
            <button
                class="w-[26px] h-[26px] flex-none hover:bg-[#113b57] rounded-primary flex items-center justify-center"
                (click)="toggleChannelOpen()">
                <mat-icon [class]="channelOpen ? '' : '-rotate-90'">arrow_drop_down</mat-icon>
            </button>
            <span class="text-[15px] font-normal flex-1" (click)="toggleChannelOpen()">Boards</span>
            <app-tooltip tooltip="Section options" position="top-right" class="show-on-hover">
                <button
                    class="w-[26px] h-[26px] flex-none hover:bg-[#113b57] rounded-primary flex items-center justify-center ml-auto"
                    [matMenuTriggerFor]="moremenu">
                    <mat-icon class="text-lg items-center justify-center" style="display: flex;">more_vert</mat-icon>
                </button>
            </app-tooltip>
            <mat-menu #moremenu="matMenu">
                <button mat-menu-item (click)="gotoBrowseChannel()">
                    <span>Browse Boards</span>
                </button>
            </mat-menu>
            <app-tooltip tooltip="Add boards" position="top-right" class="show-on-hover">
                <button
                    class="w-[26px] h-[26px] flex-none hover:bg-[#113b57] rounded-primary flex items-center justify-center"
                    [matMenuTriggerFor]="addMenu">
                    <mat-icon>add</mat-icon>
                </button>
            </app-tooltip>
            <mat-menu #addMenu="matMenu">
                <button mat-menu-item (click)="gotoBrowseChannel()">
                    <span>Browse Boards</span>
                </button>
                <button mat-menu-item (click)="openDialog()">
                    <span>Create Folder</span>
                </button>
            </mat-menu>
        </div>
        <sidebar-tree [hidden]="!channelOpen"></sidebar-tree>
    </div>
</div>