import { Component } from '@angular/core'

interface User {
  name: string
}

@Component({
  selector: 'app-browse-users',
  templateUrl: './browse-users.component.html',
  styleUrls: ['./browse-users.component.scss'],
})
export class BrowseUsersComponent {
  users: User[] = [
    { name: 'Test User 1' },
    { name: 'Test User 2' },
    { name: 'Test User 3' },
    { name: 'Test User 4' },
    { name: 'Test User 5' },
    { name: 'Test User 6' },
    { name: 'Test User 7' },
    { name: 'Test User 8' },
    { name: 'Test User 9' },
    { name: 'Test User 10' },
    { name: 'Test User 11' },
  ]
}
