import { Component } from '@angular/core'
import { ChannelService } from 'src/app/services/channel/channel.service'
import { Channel, Folder } from 'src/app/services/channel/channel.type'
import { MatDialog } from '@angular/material/dialog'
import { CreateChannelDialogComponent } from 'src/app/component/dialog/create-channel-dialog/create-channel-dialog.component'
import { DeleteChannelDialogComponent } from 'src/app/component/dialog/delete-channel-dialog/delete-channel-dialog.component'

@Component({
  selector: 'app-browse-channel',
  templateUrl: './browse-channel.component.html',
  styleUrls: ['./browse-channel.component.scss'],
})
export class BrowseChannelComponent {
  folders: Folder[] = []
  fiilteredChannels: Channel[] = []
  searchText: string = ''
  constructor(
    private channelService: ChannelService,
    public dialog: MatDialog,
  ) {
    this.folders = channelService.folders
  }

  ngOnInit() {
    this.folders.forEach((e) => {
      this.fiilteredChannels = this.fiilteredChannels.concat(e.channels)
    })
  }

  openDialog() {
    this.dialog.open(CreateChannelDialogComponent)
  }

  openDeleteChannelModal(channel: Channel) {
    this.dialog.open(DeleteChannelDialogComponent, { data: { id: channel.id } })
  }
}
