import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { LoginComponent } from './pages/login/login.component'
import { HomeComponent } from './pages/home/home.component'
import { SigninComponent } from './pages/signin/signin.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ChannelComponent } from './pages/channel/channel.component'
import { DashboardComponent } from './layouts/dashboard/dashboard.component'
import { SidebarComponent } from './layouts/dashboard/sidebar/sidebar.component'
import { BodyComponent } from './layouts/dashboard/body/body.component'
import { HeaderComponent } from './layouts/dashboard/header/header.component'
import { TreeComponent } from './layouts/dashboard/sidebar/tree/tree.component'
import { MatIconModule } from '@angular/material/icon'
import { MatBadgeModule } from '@angular/material/badge'
import { MatMenuModule } from '@angular/material/menu'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { TooltipComponent } from './component/tooltip/tooltip.component'
import { BrowseChannelComponent } from './pages/browse-channel/browse-channel.component'
import { CreateChannelDialogComponent } from './component/dialog/create-channel-dialog/create-channel-dialog.component'
import { MatDialogModule } from '@angular/material/dialog'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatButtonModule } from '@angular/material/button'
import { MatDividerModule } from '@angular/material/divider'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafePipe } from './safe.pipe';
import { CreateFolderDialogComponent } from './component/dialog/create-folder-dialog/create-folder-dialog.component';
import { BrowseUsersComponent } from './pages/browse-users/browse-users.component';
import { DeleteChannelDialogComponent } from './component/dialog/delete-channel-dialog/delete-channel-dialog.component';
import { DeleteFolderDialogComponent } from './component/dialog/delete-folder-dialog/delete-folder-dialog.component'

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    SigninComponent,
    ChannelComponent,
    DashboardComponent,
    SidebarComponent,
    BodyComponent,
    HeaderComponent,
    TreeComponent,
    TooltipComponent,
    BrowseChannelComponent,
    CreateChannelDialogComponent,
    SafePipe,
    CreateFolderDialogComponent,
    BrowseUsersComponent,
    DeleteChannelDialogComponent,
    DeleteFolderDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    DragDropModule,
    MatMenuModule,
    MatBadgeModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatDividerModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
