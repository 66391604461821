<!-- <mat-toolbar class="mat-elevation-z8 p-0 bg-transparent h-auto"> -->
<div class="h-11 flex items-center text-white w-full">
    <div class="w-fit lg:w-[220px] xl:w-[260px] pl-4 pr-5 flex justify-end flex-none 2xl:flex-1">
        <app-tooltip tooltip="History" position="bottom-left">
            <button
                class="w-[26px] h-[26px] flex justify-center items-center rounded-primary border-none bg-transparent hover:bg-[#ffffff33] outline-none opacity-80 hover:opacity-100">
                <svg data-2ui="true" aria-hidden="true" viewBox="0 0 20 20" class="w-[18px] h-[18px]" style="--s:20px;">
                    <path fill="currentColor" fill-rule="evenodd"
                        d="M2.5 10a7.5 7.5 0 1 1 15 0 7.5 7.5 0 0 1-15 0ZM10 1a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm.75 4.75a.75.75 0 0 0-1.5 0v5.5h4.5a.75.75 0 0 0 0-1.5h-3v-4Z"
                        clip-rule="evenodd"></path>
                </svg>
            </button>
        </app-tooltip>
    </div>
    <div class="flex-1 max-w-[732px] 2xl:flex-none w-auto 2xl:w-[732px]">
        <app-tooltip tooltip="Search Alleo" [controls]="['Ctrl', 'G']" position="bottom">
            <div
                class="w-full bg-[#ffffff33] hover:bg-[#ffffff3b] rounded-primary px-2 items-center flex justify-between h-[26px] cursor-pointer">
                <span class="text-[13px] font-normal whitespace-nowrap">Search Alleo</span>
                <div class="flex gap-2">
                    <app-tooltip tooltip="Filter" position="bottom">
                        <button class="border-none bg-transparent outline-none">
                            <svg data-2ui="true" aria-hidden="true" viewBox="0 0 20 20" class="w-4 h-4"
                                style="--s:16px;">
                                <path fill="currentColor" fill-rule="evenodd"
                                    d="M13.5 3.25a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2.386.25a2.501 2.501 0 0 1 4.772 0h1.864a.75.75 0 0 1 0 1.5h-1.864a2.501 2.501 0 0 1-4.771 0H2.25a.75.75 0 0 1 0-1.5h8.864Zm-7 5.75a2.501 2.501 0 0 1 4.772 0h8.864a.75.75 0 0 1 0 1.5H8.886a2.501 2.501 0 0 1-4.772 0H2.25a.75.75 0 0 1 0-1.5h1.864ZM6.5 9a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm6 5.75a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2.386.25a2.501 2.501 0 0 1 4.772 0h2.864a.75.75 0 0 1 0 1.5h-2.864a2.501 2.501 0 0 1-4.771 0H2.25a.75.75 0 0 1 0-1.5h7.864Z"
                                    clip-rule="evenodd"></path>
                            </svg>
                        </button>
                    </app-tooltip>
                    <button class="border-none bg-transparent outline-none">
                        <svg data-2ui="true" aria-hidden="true" viewBox="0 0 20 20" class="w-[15px] h-[15px]">
                            <path fill="currentColor" fill-rule="evenodd"
                                d="M9 3a6 6 0 1 0 0 12A6 6 0 0 0 9 3ZM1.5 9a7.5 7.5 0 1 1 13.307 4.746l3.473 3.474a.75.75 0 1 1-1.06 1.06l-3.473-3.473A7.5 7.5 0 0 1 1.5 9Z"
                                clip-rule="evenodd"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </app-tooltip>
    </div>
    <div class="flex pl-8 pr-4 ml-auto justify-end flex-none 2xl:flex-1">
        <app-tooltip tooltip="Help" position="bottom">
            <button
                class="w-[26px] h-[26px] flex justify-center items-center rounded-primary border-none bg-transparent hover:bg-[#ffffff33] outline-none opacity-80 hover:opacity-100 mr-3">
                <svg data-2ui="true" aria-hidden="true" viewBox="0 0 20 20" class="w-5 h-5" style="--s:20px;">
                    <path fill="currentColor" fill-rule="evenodd"
                        d="M10 2.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15ZM1 10a9 9 0 1 1 18 0 9 9 0 0 1-18 0Zm7.883-2.648c-.23.195-.383.484-.383.898a.75.75 0 0 1-1.5 0c0-.836.333-1.547.91-2.04.563-.48 1.31-.71 2.09-.71.776 0 1.577.227 2.2.729.642.517 1.05 1.294 1.05 2.271 0 .827-.264 1.515-.807 2.001-.473.423-1.08.623-1.693.703V12h-1.5v-1c0-.709.566-1.211 1.18-1.269.507-.048.827-.18 1.013-.347.162-.145.307-.39.307-.884 0-.523-.203-.87-.492-1.104C10.951 7.148 10.502 7 10 7c-.497 0-.876.146-1.117.352ZM10 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                        clip-rule="evenodd"></path>
                </svg>
            </button>
        </app-tooltip>
        <app-tooltip tooltip="Brandon" position="bottom-right">
            <button class="w-[26px] h-[26px] rounded-primary cursor-pointer overflow-hidden"
                [matMenuTriggerFor]="authMenu">
                <img src="../../../../assets/avatar/brandon.jpg" class="w-full h-full" alt="Avatar">
            </button>
        </app-tooltip>
        <mat-menu #authMenu="matMenu">
            <div (click)="$event.stopPropagation();" class="w-[300px]">
                <div class="w-full pt-2 pb-3 px-6 flex items-start gap-3">
                    <div class="flex-none rounded-primary">
                        <img src="../../../../assets/avatar/brandon.jpg" class="w-9 h-8" alt="Avatar">
                    </div>
                    <div class="flex-1">
                        <div class="font-black text-primary">Brandon</div>
                        <div class="flex items-center gap-[6px]">
                            <div class="w-2 h-2 rounded-full bg-[#007a5a]"></div>
                            <div class="flex-1 text-[13px] text-primary-70">Active</div>
                        </div>
                    </div>
                </div>
                <div class="px-6 mb-2">
                    <button
                        class="w-full flex items-center border border-[#1d1c1d21] hover:border-[#1d1c1d80] text-[#001c1db3] hover:text-primary bg-white rounded-primary h-9 px-[7px] action-btn outline-none">
                        <span class="c-icon c-action-icon mr-4 flex-none flex items-center"></span>
                        <div class="flex-1 text-left">Update your status</div>
                    </button>
                </div>
                <div
                    class="px-6 h-7 flex items-center bg-transparent hover:bg-[#1264a3] text-primary hover:text-white text-[15px] cursor-pointer gap-1">
                    Set yourself as <strong class="">away</strong>
                </div>
                <div
                    class="px-6 h-7 flex items-center justify-between bg-transparent hover:bg-[#1264a3] text-primary hover:text-white text-[15px] cursor-pointer">
                    Pause notifications
                    <svg data-2ui="true" aria-hidden="true" viewBox="0 0 20 20" class="w-5 hh-5">
                        <path fill="currentColor" fill-rule="evenodd"
                            d="M7.72 5.72a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 0 1-1.06-1.06L10.94 10 7.72 6.78a.75.75 0 0 1 0-1.06Z"
                            clip-rule="evenodd"></path>
                    </svg>
                </div>
                <div class="w-full my-2 border-t border-primary-30"></div>
                <div
                    class="px-6 h-7 flex items-center bg-transparent hover:bg-[#1264a3] text-primary hover:text-white text-[15px] cursor-pointer gap-1">
                    Profile
                </div>
                <div
                    class="px-6 h-7 flex items-center bg-transparent hover:bg-[#1264a3] text-primary hover:text-white text-[15px] cursor-pointer gap-1">
                    Preferenced
                </div>
                <div class="w-full my-2 border-t border-primary-30"></div>
                <div class="px-6 h-7 flex items-center bg-transparent hover:bg-[#1264a3] text-primary hover:text-white text-[15px] cursor-pointer gap-1"
                    (click)="signOut()">
                    Sign out of Alleo
                </div>
            </div>
        </mat-menu>
    </div>
</div>
<!-- </mat-toolbar> -->