<div class="w-full" cdkDropListGroup>
    <div class="w-fulll" *ngFor="let folder of folders">
        <div class="w-full h-7 flex items-center px-4 gap-2  bg-transparent text-[#ffffffb3] cursor-pointer">
            <button
                class="w-[26px] h-[26px] flex-none hover:bg-[#113b57] rounded-primary flex items-center justify-center"
                (click)="toggleFolder(folder)">
                <mat-icon class="text-lg justify-center items-center" style="display: flex;">{{folder.open ? 'folder' :
                    'folder_open'}}</mat-icon>
            </button>
            <span class="text-[15px] font-normal flex-none" (click)="toggleFolder(folder)">{{folder.name}}</span>
            <app-tooltip tooltip="Add boards" position="top-right" class="show-on-hover ml-auto">
                <button
                    class="show-on-hover w-[26px] h-[26px] flex-none hover:bg-[#113b57] rounded-primary flex items-center justify-center"
                    [matMenuTriggerFor]="addMenu">
                    <mat-icon>add</mat-icon>
                </button>
            </app-tooltip>
            <mat-menu #addMenu="matMenu">
                <button mat-menu-item (click)="openDialog(folder.id)">
                    <span>Create Board</span>
                </button>
                <button mat-menu-item (click)="deleteFolder(folder.id)">
                    <span>Delete Folder</span>
                </button>
            </mat-menu>
        </div>
        <div cdkDropList [cdkDropListData]="folder.channels" class="w-full" (cdkDropListDropped)="onDrop($event)"
            [hidden]="!folder.open">
            <div class="w-full" *ngFor="let channel of folder.channels" cdkDrag [cdkDragData]="channel">
                <div class="w-full h-7 flex items-center px-4 pl-6 gap-2 hover:bg-[#113b57] bg-transparent text-[#ffffffb3] cursor-pointer menu-link"
                    [routerLink]="'/board/' + channel.id" routerLinkActive="active">
                    <button class="w-[26px] h-[26px] flex-none rounded-primary flex items-center justify-center">
                        <svg data-2ui="true" *ngIf="!channel.private" aria-hidden="true"
                            data-qa="sidebar-channel-icon-prefix" data-sidebar-channel-icon="channel"
                            viewBox="0 0 20 20" class="w-[18px] h-[18px]" style="--s:16px;">
                            <path fill="currentColor" fill-rule="evenodd"
                                d="M9.74 3.878a.75.75 0 1 0-1.48-.255L7.68 7H3.75a.75.75 0 0 0 0 1.5h3.67L6.472 14H2.75a.75.75 0 0 0 0 1.5h3.463l-.452 2.623a.75.75 0 0 0 1.478.255l.496-2.878h3.228l-.452 2.623a.75.75 0 0 0 1.478.255l.496-2.878h3.765a.75.75 0 0 0 0-1.5h-3.506l.948-5.5h3.558a.75.75 0 0 0 0-1.5h-3.3l.54-3.122a.75.75 0 0 0-1.48-.255L12.43 7H9.2l.538-3.122ZM11.221 14l.948-5.5H8.942L7.994 14h3.228Z"
                                clip-rule="evenodd"></path>
                        </svg>
                        <svg data-2ui="true" *ngIf="channel.private" aria-hidden="true"
                            data-qa="sidebar-channel-icon-prefix" data-sidebar-channel-icon="lock" viewBox="0 0 20 20"
                            class="w-[18px] h-[18px]" style="--s:16px;">
                            <path fill="currentColor" fill-rule="evenodd"
                                d="M10 1.5A4.5 4.5 0 0 0 5.5 6v1.5h-.25A2.25 2.25 0 0 0 3 9.75v6.5c0 .966.784 1.75 1.75 1.75h10.5A1.75 1.75 0 0 0 17 16.25v-6.5a2.25 2.25 0 0 0-2.25-2.25h-.25V6A4.5 4.5 0 0 0 10 1.5Zm3 6V6a3 3 0 1 0-6 0v1.5h6ZM4.5 9.75A.75.75 0 0 1 5.25 9h9.5a.75.75 0 0 1 .75.75v6.5a.25.25 0 0 1-.25.25H4.75a.25.25 0 0 1-.25-.25v-6.5Z"
                                clip-rule="evenodd"></path>
                        </svg>
                    </button>
                    <span class="text-[15px] font-normal">{{channel.name}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="w-full h-7 flex items-center px-4 hover:bg-[#113b57] bg-[transparent] text-[#ffffffb3] cursor-pointer">
    <div class="flex items-center gap-2" [matMenuTriggerFor]="addChannelMenu">
        <button class="w-[26px] h-[26px] flex-none bg-[#ffffff1a] rounded-md flex items-center justify-center">
            <mat-icon class="text-lg justify-center items-center" style="display: flex;">add</mat-icon>
        </button>
        <span class="text-[15px] font-normal">Add Boards</span>
    </div>
</div>
<mat-menu #addChannelMenu="matMenu">
    <button mat-menu-item (click)="openDialog('')">
        <span>Create a new board</span>
    </button>
    <button mat-menu-item routerLink="/browse-boards">
        <span>Browse boards</span>
    </button>
</mat-menu>