import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ChannelService } from 'src/app/services/channel/channel.service'

export interface DialogData {
  id?: string
}

@Component({
  selector: 'app-delete-channel-dialog',
  templateUrl: './delete-channel-dialog.component.html',
  styleUrls: ['./delete-channel-dialog.component.scss'],
})
export class DeleteChannelDialogComponent {
  permanentDelete: boolean = false
  constructor(
    public dialogRef: MatDialogRef<DeleteChannelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private channelService: ChannelService,
  ) {}

  handleDelete() {
    if (this.data.id) this.channelService.deleteChannel(this.data.id)
    this.dialogRef.close()
  }
}
