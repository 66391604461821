import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { ChannelService } from 'src/app/services/channel/channel.service'

export interface DialogData {
  id?: string
}

@Component({
  selector: 'app-delete-folder-dialog',
  templateUrl: './delete-folder-dialog.component.html',
  styleUrls: ['./delete-folder-dialog.component.scss'],
})
export class DeleteFolderDialogComponent {
  permanentDelete: boolean = false
  constructor(
    public dialogRef: MatDialogRef<DeleteFolderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private channelService: ChannelService,
    private router: Router,
  ) {}

  handleDelete() {
    if (this.data.id) this.channelService.deleteFolder(this.data.id)
    this.dialogRef.close()
    this.router.navigate(['/browse-boards'])
  }
}
