import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Channel, Folder } from 'src/app/services/channel/channel.type'
import { ChannelService } from 'src/app/services/channel/channel.service'
import { FormBuilder } from '@angular/forms'
import { Validators } from '@angular/forms'

export interface DialogData {
  id?: string
  name?: string
  folder?: string
  url?: string
  private?: boolean
}

@Component({
  selector: 'app-create-channel-dialog',
  templateUrl: './create-channel-dialog.component.html',
  styleUrls: ['./create-channel-dialog.component.scss'],
})
export class CreateChannelDialogComponent {
  channelForm = this.fb.group({
    name: ['', Validators.required],
    folder: ['', Validators.required],
    url: ['', Validators.required],
    private: [false],
  })
  folders: Folder[] = this.channelService.folders
  editMode: boolean = false

  constructor(
    public dialogRef: MatDialogRef<CreateChannelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private channelService: ChannelService,
  ) {}

  ngOnInit() {
    if (this.data) {
      if (this.data.id) {
        this.editMode = true
      }
      this.channelForm.setValue({
        name: this.data.name || '',
        folder: this.data.folder || '',
        url: this.data.url || '',
        private: this.data.private || false,
      })
    }
  }

  onSubmit() {
    let formData: Channel = {
      id: this.editMode
        ? (this.data.id as string)
        : new Date().getMilliseconds().toString(),
      name: '',
      folder: '',
      url: '',
      private: true,
      users: [],
    }

    if (this.channelForm.value) {
      formData.folder = this.channelForm.value.folder as string
      formData.name = this.channelForm.value.name as string
      formData.folder = this.channelForm.value.folder as string
      formData.url = this.channelForm.value.url as string
      formData.private = this.channelForm.value.private as boolean
    }
    if (this.editMode) {
      this.channelService.updateChannel(formData)
    } else {
      this.channelService.addChannel(formData)
    }
    this.dialogRef.close()
  }
}
