import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { BrowseChannelComponent } from './pages/browse-channel/browse-channel.component'
import { BrowseUsersComponent } from './pages/browse-users/browse-users.component'
import { ChannelComponent } from './pages/channel/channel.component'
import { LoginComponent } from './pages/login/login.component'
import { SigninComponent } from './pages/signin/signin.component'

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'signup',
    component: SigninComponent,
  },
  {
    path: 'board/:id',
    component: ChannelComponent,
  },
  {
    path: 'browse-boards',
    component: BrowseChannelComponent,
  },
  {
    path: 'browse-users',
    component: BrowseUsersComponent,
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
