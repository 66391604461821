import { Component } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { ChannelService } from 'src/app/services/channel/channel.service'
import { Channel } from 'src/app/services/channel/channel.type'
import { MatDialog } from '@angular/material/dialog'
import { CreateChannelDialogComponent } from 'src/app/component/dialog/create-channel-dialog/create-channel-dialog.component'

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss'],
})
export class ChannelComponent {
  channel: Channel | null = null
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public channelService: ChannelService,
    public dialog: MatDialog,
  ) {
    this.findChannel()
    let that = this
    window.addEventListener('message', function (e) {
      if (e.data.type == 'update_channel') {
        that.findChannel()
      }
    })
  }

  findChannel() {
    this.route.params.subscribe((params: any) => {
      let channel: Channel | undefined = undefined
      this.channelService.folders.forEach((e) => {
        if (e.channels.find((e) => e.id === params.id)) {
          channel = e.channels.find((e) => e.id === params.id)
        }
      })
      if (channel) {
        this.channel = channel
      } else {
        this.router.navigate(['/browse-boards'])
      }
    })
  }

  openDialog() {
    this.dialog.open(CreateChannelDialogComponent, {
      data: this.channel,
    })
  }
}
